import React from 'react';
import LinkButton from '../components/LinkButton';
import SEO from '../components/seo';
import styles from './styles/404.module.scss';
import QSvg from '../images/q/q-404.svg';
import Layout from '../components/Layout';

const PageNotFound = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <div className={styles.container}>
        <QSvg className={styles.q} />
        <div>
          <strong className={styles.title}>
            We can’t seem to find the page you’re looking for!{' '}
          </strong>
        </div>
        <div className={styles.description}>
          Don’t worry we’ll point you back in the right direction.
        </div>
        <div className={styles.buttonContainer}>
          <LinkButton
            href="/"
            background="#34363E"
            className={styles.learnMoreButton}
          >
            Home
          </LinkButton>
          <LinkButton
            href="/why-life-insurance"
            className={styles.supriseMeButton}
          >
            Suprise me
          </LinkButton>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
